import {StoppageKind} from '@hconnect/common/types'
import {DateRange} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {prepareAggregatedStoppageCodePayload} from '../helpers'
import {normalizeDateRange} from '../helpers/dateFormat.helpers'
import {AggregatedStoppageStatisticsResponse, TimeStep} from '../types'

import {usePlantTimezone} from '../../shared/hooks/useTimezone'

const QueryKey = 'aggregated-stoppage-statistics'

export const useAggregatedStoppageStatistics = (
  plantId: string,
  payload: DateRange<Date> & {
    mainEquipmentNumbers?: string[]
    equipmentNumbers?: string[]
    kinds?: StoppageKind[]
    stoppageCodes?: string[]
    timeStep: TimeStep
  },
  options?: UseQueryOptions<AggregatedStoppageStatisticsResponse, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const timezone = usePlantTimezone({plantId})

  return useQuery<AggregatedStoppageStatisticsResponse, AxiosError>(
    [QueryKey, plantId, payload, timezone],
    async () => {
      const {startDate, endDate} = normalizeDateRange(
        {startDate: payload.from, endDate: payload.to},
        timezone
      )
      const path = `/downtime-dashboard/${plantId}/stoppages/time-period-statistics`
      const response = await axiosInstance.get<AggregatedStoppageStatisticsResponse>(path, {
        params: prepareAggregatedStoppageCodePayload({
          to: endDate,
          from: startDate,
          mainEquipmentNumbers: payload.mainEquipmentNumbers,
          equipmentNumbers: payload.equipmentNumbers,
          kinds: payload.kinds,
          stoppageCodes: payload.stoppageCodes,
          timeStep: payload.timeStep
        })
      })

      return response.data
    },
    {
      staleTime: 1000 * 60 * 15, // 15 mins
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
