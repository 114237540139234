import {StoppageKind, StoppageType} from '@hconnect/common/types'
import {DateRange} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {prepareAggregatedStoppageCodePayload} from '../helpers'
import {normalizeDateRange} from '../helpers/dateFormat.helpers'
import {
  AggregatedStoppageCodeStatistics,
  DurationInterval,
  IssueGroupingKey,
  TimeStep
} from '../types'

const QueryKey = 'stoppage-code-stats'
const INTERVAL_PERIOD_IN_MS = 300000

export const useStoppageCodeStats = (
  plantId: string,
  payload: DateRange<Date> & {
    groupBy: IssueGroupingKey
    selectedMainEquipments?: string[]
    selectedEquipments?: string[]
    selectedStoppages?: StoppageKind[]
    selectedStoppageCodes?: string[]
    timeStep?: TimeStep
    stoppageType?: StoppageType[]
    durationInterval?: DurationInterval
  },
  options?: UseQueryOptions<AggregatedStoppageCodeStatistics, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const timezone = usePlantTimezone({plantId})

  return useQuery<AggregatedStoppageCodeStatistics, AxiosError>(
    [QueryKey, plantId, payload, timezone],
    async () => {
      const {startDate, endDate} = normalizeDateRange(
        {startDate: payload.from, endDate: payload.to},
        timezone
      )
      const response = await axiosInstance.get<AggregatedStoppageCodeStatistics>(
        `/downtime-dashboard/${plantId}/stoppages/stoppage-code-statistics`,
        {
          params: prepareAggregatedStoppageCodePayload({
            to: endDate,
            from: startDate,
            mainEquipmentNumbers: payload.selectedMainEquipments,
            equipmentNumbers: payload.selectedEquipments,
            kinds: payload.selectedStoppages,
            stoppageCodes: payload.selectedStoppageCodes,
            groupBy: payload.groupBy,
            timeStep: payload.timeStep,
            stoppageType: payload.stoppageType,
            durationInterval: payload.durationInterval
          })
        }
      )

      return response.data
    },
    {
      staleTime: 1000 * 60 * 15, // 15 mins
      refetchInterval: INTERVAL_PERIOD_IN_MS,
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
