import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {normalizeDateRange} from '../helpers/dateFormat.helpers'
import {AggregatedStoppage, AggregatedStoppagesPayload} from '../types'


const QueryKey = 'aggregated-stoppages'

export const useAggregatedStoppages = (
  plantId: string,
  payload: AggregatedStoppagesPayload,
  options?: UseQueryOptions<AggregatedStoppage[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const timezone = usePlantTimezone({plantId})

  return useQuery<AggregatedStoppage[], AxiosError>(
    [QueryKey, plantId, payload, timezone],
    async () => {
      const {startDate, endDate} = normalizeDateRange(
        {startDate: payload.from, endDate: payload.to},
        timezone
      )
      payload.from = startDate
      payload.to = endDate

      const path = `/downtime-dashboard/${plantId}/aggregated-stoppages`
      const response = await axiosInstance.post<AggregatedStoppage[]>(path, {
        ...payload
      })

      return response.data
    },
    {
      staleTime: 1000 * 60 * 15, // 15 mins
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
