import {
  Stoppage,
  AttachmentFile,
  Attachment,
  EquipmentData,
  StoppageKind
} from '@hconnect/common/types'
import {Iso8601} from '@hconnect/uikit'
import {ReactNode} from 'react'

export type StoppageItem = {
  timeFrame: string | ReactNode
  stoppageCode: string
  cost: string
  hasSalesImpact: boolean
}

export type StoppageItemKey = keyof StoppageItem

export type StoppageCreate = Stoppage & {
  attachments?: AttachmentFile[]
  splitOptions?: {
    sourceStoppageId: string
    splitPoint: Iso8601
  }
}

export type StoppageEdit = Stoppage & {
  id: string
  lastModified: Iso8601
  attachmentsToRemove: string[]
  newAttachments?: AttachmentFile[]
}

export type StoppageEvent = Stoppage & {
  id: string
  attachments?: Attachment[]
}

export type AggregatedStoppageRcfaAvailability = {
  kilnStoppageCount: number
  longKilnStoppageCount: number
  longKilnStoppageRcfaCount: number
  stoppageRcfaCount: number
}

export enum TimeStep {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month'
}

export enum TimeStepForTitle {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export type DowntimeStoppage = {
  id: string
  plantId: string
  title: string
  description: string
  mainEquipment: EquipmentData
  equipment: EquipmentData
  start: Iso8601
  end: Iso8601
  stoppageCode: string
  stoppageReason: string
  kind: StoppageKind
  pxTrendId: number
}

export enum IssueSortingKey {
  DURATION = 'duration',
  FREQUENCY = 'frequency'
}

export enum IssueGroupingKey {
  STOPPAGE_CODE = 'stoppageCode',
  MAIN_EQUIPMENT = 'mainEquipmentNumber',
  EQUIPMENT_NUMBER = 'equipmentNumber',
  TIME_INTERVAL = 'timeInterval'
}

export enum DurationType {
  upTo1h = 'upTo1h',
  between1hAnd24h = 'between1hAnd24h',
  moreThan24h = 'moreThan24h'
}

export type DurationInterval = {
  start: string
  end: string | null
}
